@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2&display=swap');

.buy-section {
  font-family: 'Baloo Bhai 2', cursive;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/fred.png');
  background-size: 50%;
  background-position: left;
  background-repeat: no-repeat;
  text-align: center;
  padding: 20px;
  opacity: 0;
  animation: fadeIn 2s ease-out forwards;
}

.buy-section h1 {
  margin-bottom: 20px;
  font-size: 5rem;
}

.buy-section p {
  font-size: 2rem;
  max-width: 600px;
  margin-bottom: 30px;
}

.cool-button {
  padding: 15px 30px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(45deg, #ff6b6b, #f06595);
  border: none;
  border-radius: 50px;
  box-shadow: 0 4px 15px rgba(255, 107, 107, 0.4);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  position: relative;
  overflow: hidden;
  opacity: 0;
  animation: fadeInUp 2s ease-out forwards 2s;
}

.cool-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transition: all 0.5s ease-in-out;
}

.cool-button:hover::before {
  width: 0;
  height: 0;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.cool-button:hover {
  background: linear-gradient(45deg, #f06595, #ff6b6b);
  box-shadow: 0 6px 20px rgba(240, 101, 149, 0.4);
  transform: translateY(-3px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
