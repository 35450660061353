@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2&display=swap');

header {
    font-family: 'Baloo Bhai 2', cursive;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background: #282c34;
    padding: 10px;
    color: white;
    border-bottom: 8px solid #ff6b6b;
  }
  
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .menu {
    display: flex;
    align-items: center;
    margin-left: 5vh;
  }
  
  .menu ul {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
  }
  
  .menu ul li {
    display: inline;
  }
  
  .menu ul li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.6rem;
    transition: text-decoration 0.5s ease, color 0.5s ease;
  }

  .menu ul li a:hover {
    text-decoration: underline;
    text-decoration-color: #ff6b6b;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }
  
  .icons {
    display: flex;
    gap: 25px;
    align-items: center;
    margin-right: 2%;
  }
  
  .icons img {
    width: 30px;
    height: 30px;
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(49%) sepia(100%) saturate(500%) hue-rotate(-50deg) contrast(100%);
    opacity: 0;
    animation: fadeIn 0.6s ease-out forwards;
    transition: filter 0.3s ease, border-bottom 0.5s ease;
    border-bottom: 2px solid transparent;
  }

  .icons img:nth-child(1) {
    animation-delay: 0.2s;
}

.icons img:nth-child(2) {
    animation-delay: 0.6s;
}

.icons img:nth-child(3) {
    animation-delay: 1s;
}

.icons img:hover {
  filter: brightness(2) saturate(150%) invert(49%) sepia(100%) saturate(1000%) hue-rotate(-50deg) contrast(150%);
  border-bottom: 2px solid #ff6b6b;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

  