@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2&display=swap');

.coin-line {
    font-family: 'Baloo Bhai 2', cursive;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    background-color: #282c34;
    padding: 10px 0;
    display: flex;
    border-top: 8px solid #ff6b6b;
    border-bottom: 8px solid #ff6b6b;
  }
  
  .coin-text {
    display: inline-block;
    white-space: nowrap;
    animation: scroll 180s linear infinite;
    color: #ffffff;
    font-size: 1.5rem;
  }
  
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  