@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2&display=swap');

.memes-section {
  font-family: 'Baloo Bhai 2', cursive;
  padding: 10px;
  background-color: #e2e2e2;
  text-align: center;
}

.memes-section h1 {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 4rem;
  text-align: center;
}

.memes-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.meme-item {
  width: 100%;
  padding-top: 100%;
  position: relative;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
}

.meme-item.fade-in-up {
  opacity: 1;
  transform: translateY(0);
}

.meme-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
