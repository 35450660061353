@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2&display=swap');

.tokenomics-section {
  font-family: 'Baloo Bhai 2', cursive;
  padding: 60px;
  background-color: #f4f4f4;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.tokenomics-section.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.tokenomics-info {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.tokenomics-item {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.tokenomics-item h2 {
  margin-top: 0;
  padding-top: 0;
  font-size: 1.5rem;
  color: #333333;
}

.tokenomics-item p {
  font-size: 1.2rem;
  color: #ff6b6b;
  font-weight: bold;
}

.tokenomics-item.supply {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.tokenomics-item.supply.fade-in-up {
  opacity: 1;
  transform: translateY(0);
}

.contract-address {
  margin-top: 10px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.contract-address h2 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #333333;
}

.contract-address p {
  font-size: 1.2rem;
  color: #ff6b6b;
  font-weight: bold;
  word-break: break-all;
}

h1 {
  font-size: 4rem;
  padding: 0;
  margin: 0;
}
